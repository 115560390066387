import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import AddApi from "./components/AddApi";
import { getServiceName, addApi, addAsaAccount, getToken } from "./api/addApi";
import { Toastr } from "../../../components/Toastr/Toastr";
import generatePassword from "../../../utils/generatePassword";

export default function Index({
  setActiveManageComponent,
  handleChnageRender
}) {
  const [showCredentials, setShowCredentials] = useState(false);
  const [serviceName, setServiceName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService] = useState({
    company_type_id: null,
    api_service_id: null,
    api_service_name: ""
  });
  const [addApiLoading, setAddApiLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [roomNumber, setRoomNumber] = useState(0);
  const { websitesList, websiteID } = useSelector((state) => state.generalData);
  const userName = websitesList
    .find((ele) => ele.id === websiteID)
    ?.name.replace(/[^a-zA-Z0-9]/g, "");
  const { t } = useTranslation();

  useEffect(() => {
    setPassword(generatePassword(24));
  }, []);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedServiceData = serviceName.find(
      (service) => service.api_service_name === selectedValue
    );
    setSelectedService(
      selectedServiceData || {
        company_type_id: null,
        api_service_id: null,
        api_service_name: ""
      }
    );
  };

  const handleGenerateCredentials = () => {
    setShowCredentials(true);
  };

  useEffect(() => {
    const fetchServiceName = async () => {
      setLoading(true);
      const response = await getServiceName(websiteID);
      if (response) {
        setServiceName(response.data.result);
      }
      setLoading(false);
    };
    fetchServiceName();
  }, [websiteID]);

  useEffect(() => {
    const fetchToken = async () => {
      const response = await getToken({ websiteId: websiteID });
      if (response.data.status === "ok") {
        setToken(response.data.token);
      }
    };
    fetchToken();
  }, [websiteID]);

  const handleAddApi = async (event) => {
    event.preventDefault();
    try {
      setAddApiLoading(true);
      if (!selectedService.api_service_name) {
        Toastr.error(t("ManageApi.createAPi.addError"));
        setAddApiLoading(false);
        return; // Return here to exit the function early
      }
      // Make parallel API calls using Promise.all
      if (selectedService.api_service_name === "ASA") {
        const [apiResponse, asaResponse] = await Promise.all([
          addApi({
            website_id: websiteID,
            company_type_id: selectedService.company_type_id,
            api_service_id: selectedService.api_service_id,
            room_number: Number(roomNumber)
          }),
          addAsaAccount({
            username: userName,
            website_id: websiteID,
            password
          })
        ]);
        // Check if both responses are successful
        if (
          apiResponse.data.result.success &&
          asaResponse.data.status === "ok"
        ) {
          setAddApiLoading(false);
          Toastr.success(t("ManageApi.createAPi.addSuccess"));
          setActiveManageComponent(0);
          handleChnageRender();
        }
      } else {
        const apiResponse = await addApi({
          website_id: websiteID,
          company_type_id: selectedService.company_type_id,
          api_service_id: selectedService.api_service_id
        });
        if (apiResponse.data.result.success) {
          setAddApiLoading(false);
          Toastr.success(t("ManageApi.createAPi.addSuccess"));
          setActiveManageComponent(0);
          handleChnageRender();
        }
      }
    } catch (error) {
      setAddApiLoading(false);
      Toastr.error(t("ManageApi.wrongMessage"));
    }
  };

  return (
    <AddApi
      showCredentials={showCredentials}
      handleGenerateCredentials={handleGenerateCredentials}
      setActiveManageComponent={setActiveManageComponent}
      serviceName={serviceName}
      loading={loading}
      handleChange={handleChange}
      selectedService={selectedService}
      handleAddApi={handleAddApi}
      addApiLoading={addApiLoading}
      userName={userName}
      password={password}
      token={token}
      setRoomNumber={setRoomNumber}
    />
  );
}

Index.propTypes = {
  setActiveManageComponent: PropTypes.func,
  handleChnageRender: PropTypes.func
};
