/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import close from "../../../assets/icons/close.png";
import search from "../../../assets/icons/search.png";
import "./searchbar.scss";

export default function SearchBar({
  onSearch,
  height = "2.5rem",
  placeholder
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    const value = event?.target?.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const handleClear = () => {
    setSearchTerm("");
    onSearch("");
  };

  return (
    <div className="search-bar-main">
      <input
        type="text"
        className="input-placeholder"
        value={searchTerm}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          height: height
        }}
      />
      <div className="flex">
        {/* Search Icon */}

        {!searchTerm && (
          <div className="search-div">
            <img src={search} className="search-icon" alt="Search" />
          </div>
        )}

        {/* Search Icon ends */}

        {/* Clear Icon */}
        {searchTerm && (
          <button type="button" onClick={handleClear} className="clear-div">
            <img src={close} className="clear-icon" alt="Clear" />
          </button>
        )}
        {/* Clear Icon ends */}
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired
};
